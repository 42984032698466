import botIcon1 from './assets/bot-icons/bot-icon-1.png';
import { MODEL_NAME } from './redux/features/conversations/ConversationsState';
import style3d from './assets/images/style/3d.png';
import styleAcrylicAndOil from './assets/images/style/acrylic-and-oil.png';
import styleDigitalIllustration from './assets/images/style/digital-illustration.png';
import styleDramaticLightning from './assets/images/style/dramatic-lightning.png';
import styleGeometric from './assets/images/style/geometric.png';
import styleLandscape from './assets/images/style/landscape.png';
import styleNeon from './assets/images/style/neon.png';
import stylePencil from './assets/images/style/pencil.png';
import styleRealisticPhoto from './assets/images/style/realistic-photo.png';
import styleVectorStyle from './assets/images/style/vector-style.png';
import styleWatercolor from './assets/images/style/watercolor.png';

export const Constants = {
    app: {
        name: 'Conversation',
        updateCheckIntervalSeconds: 60 * 5,
        CONNECTION_ALERT_ID: 'connection-alert',
        importTypes: '.txt,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.md,.jpg,.jpeg,.png,.tif,.tiff,.bmp,.gif,.csv,.eml,.html,.sql,.json,.yml,.yaml,.diff,.py,.java,.conf,.log,.css',
    },
    bot: {
        profile: {
            id: 'bot',
            fullName: 'Conversation',
            emailAddress: '',
            photo: botIcon1,
        },
        fileExtension: 'skcb',
        typingIndicatorTimeoutMs: 5000,
    },
};

export const modelNames = Object.values(MODEL_NAME);
//eslint-disable-next-line
export const modelLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Google Gemini 1.5 Pro',
    [MODEL_NAME.AZURE_TURBO]: 'OpenAI GPT 4o',
};
//eslint-disable-next-line
export const modelSubLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Powerful for querying documents',
    [MODEL_NAME.AZURE_TURBO]: 'More creative',
};

export const WelcomeMessage = `
Hello 👋, I am MaIA! How can I help ?
`;
export const WelcomeMessageImage = `
Hello, I am MaIA , please describe the image you would like to create.
`;

export const formatLabels: Record<string, string> = {
    '1:1': 'Square (1:1)',
    '9:16': 'Vertical (9:16)',
    '16:9': 'Widescreen (16:9)',
    '3:4': 'Portrait (3:4)',
    '4:3': 'Landscape (4:3)',
};

export const formats = ['1:1', '9:16', '16:9', '3:4', '4:3'];

export const styleImageWithLabels: Record<string, { image: string; label: string }> = {
    '3d': {
        image: style3d,
        label: '3D',
    },
    'acrylic-and-oil': {
        image: styleAcrylicAndOil,
        label: 'Acrylic And Oil',
    },
    'digital-illustration': {
        image: styleDigitalIllustration,
        label: 'Digital Illustration',
    },
    'dramatic-lightning': {
        image: styleDramaticLightning,
        label: 'Dramatic Lightning',
    },
    geometric: {
        image: styleGeometric,
        label: 'Geometric',
    },
    landscape: {
        image: styleLandscape,
        label: 'Landscape',
    },
    neon: {
        image: styleNeon,
        label: 'Neon',
    },
    pencil: {
        image: stylePencil,
        label: 'Pencil',
    },
    'realistic-photo': {
        image: styleRealisticPhoto,
        label: 'Realistic Photo',
    },
    'vector-style': {
        image: styleVectorStyle,
        label: 'Vector Style',
    },
    watercolor: {
        image: styleWatercolor,
        label: 'Watercolor',
    },
};

export const angles = [
    'wide-angle',
    'bird-eye',
    'close-up',
    'extreme-close-up',
    'medium-shot',
    'long-shot',
    'high-angle',
    'low-angle',
    'dutch-angle',
    'eye-level-shot',
    'tilted-shot',
];

export const anglesLabels: Record<string, string> = {
    'wide-angle': 'Wide Angle',
    'bird-eye': 'Bird Eye',
    'close-up': 'Close Up',
    'extreme-close-up': 'Extreme Close Up',
    'medium-shot': 'Medium Shot',
    'long-shot': 'Long Shot',
    'high-angle': 'High Angle',
    'low-angle': 'Low Angle',
    'dutch-angle': 'Dutch Angle',
    'eye-level-shot': 'Eye Level Shot',
    'tilted-shot': 'Tilted Shot',
};

export const MessageTypes = {
    HELP:
        'If you need to write a prompt, keep in mind the Role / Objective / Specifics framework.\n' +
        '\n' +
        '1. The **role** will help me understand and adapt to your context. (i.e. "I am a communication manager")\n' +
        '\n' +
        '2. The **objective** gives me a specific task to handle for you. (i.e. "write an email for a global communication")\n' +
        '\n' +
        '3. The **specifics** will finally provide me with all the details to give you a precise answer. (i.e. "use bullet points to structure the sections, with a formal tone and in 10 sentences max")\n' +
        '\n',
    TIPS:
        'Here are some tips to improve your prompts:\n' +
        '\n' +
        '1. **Provide clear structure and instructions.** For complicated tasks, break them down into simpler steps and ask them one by one in a back-and-forth chat.\n' +
        '\n' +
        '2. To get a good first answer, **bring all the specifics that you need:** Tone, Length of the answer, Context... Even one or two examples of the answer format that you are expecting.\n' +
        '\n' +
        '3. **Make multiple interactions to get the proper result.** Be specific on what I should keep, what I must drop and how I should modify some elements of my answer.\n' +
        '\n' +
        '4. When uploading a document, **ask me explicitly to interact with it.** If you have doubt that I have not taken all the document into consideration, ask me to provide you a structured summary of it.\n' +
        '\n' +
        "5. **You don’t need to bother with politeness** when talking to an AI like me, so feel free to skip the 'please,' 'hello,' 'thanks' and save time to focus on your prompt.",
    EXAMPLE1:
        '**I am an event coordinator at Cheval Blanc, write a post on our new hotel Cheval Blanc St-Tropez.** The post is for the website, it should be SEO friendly and ensure the highest standard of luxury tone. Provide appealing elements regarding the south of France friendly climate, luxury lifestyle of St-Tropez, the spa to relax and Provençale cuisine. The opening is on the 8 May. The post should have 20 sentences maximum.',
    EXAMPLE2:
        '**I am a product marketing Manager at Kenzo, write the agenda for our meeting,** the purpose is to prepare a brief of the product marketing strategy regarding the new collection for the Marketing and Communication department. We need to review the collection assets, split the brief production between each participant and plan the meeting with the marketing and communication department. Make a clear format for the agenda, use bullet points, 10 sentences max.',
    EXAMPLE3:
        '**I am a store manager at Guerlain, in need to find information about perfume ingredients.** I have uploaded a document with all the ingredients for every perfume that are displayed in the store. Use this document to provide me a list of every perfume that contains citronellol.',
    EXAMPLE4:
        '**I am a logistic operation officer at Tag Heuer, I need to get all information about duty taxes of luxury watches for the Korean market.** I have uploaded a document that gives all the information about Korean duties. First, make a list of all the different sections of the document. Then provide me all the details about duty for luxury goods, watches, jewelries. Illustrate the details with examples.',
} as const;

export type MessageType = (typeof MessageTypes)[keyof typeof MessageTypes];
