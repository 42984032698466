import React from 'react';
import { mergeClasses } from '@fluentui/react-components';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { addMessageToConversationFromUser } from '../../../redux/features/conversations/conversationsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { MessageType, MessageTypes } from '../../../Constants';
import { useChat } from '../../../libs/hooks';
import styles from './WelcomeButtons.module.css';
import { setSelectedTab } from '../../../redux/features/app/appSlice';

const PromptLibraryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33398 2H12.6673V14.1876L8.00065 12.0338L3.33398 14.1876V2ZM4.00065 2.66667V13.1457L8.00065 11.2995L12.0007 13.1457V2.66667H4.00065Z"
            fill="#1A1D20"
        />
    </svg>
);

const HelpBuildPromptIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0007 7.99967C14.0007 11.3134 11.3144 13.9997 8.00065 13.9997C4.68694 13.9997 2.00065 11.3134 2.00065 7.99967C2.00065 4.68597 4.68694 1.99967 8.00065 1.99967C11.3144 1.99967 14.0007 4.68597 14.0007 7.99967ZM14.6673 7.99967C14.6673 11.6816 11.6825 14.6663 8.00065 14.6663C4.31875 14.6663 1.33398 11.6816 1.33398 7.99967C1.33398 4.31778 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31778 14.6673 7.99967ZM8.25928 5.02369C8.25928 5.30369 8.48328 5.53703 8.76328 5.53703C9.04328 5.53703 9.27662 5.30369 9.27662 5.02369C9.27662 4.74369 9.04328 4.51969 8.76328 4.51969C8.48328 4.51969 8.25928 4.74369 8.25928 5.02369ZM6.55128 8.44903C7.29795 7.51569 7.57795 7.57169 7.76462 7.61836L6.32728 10.9877C6.19662 11.305 6.28062 11.4264 6.52328 11.4264C7.11128 11.4264 8.10995 10.745 8.63262 9.96103L8.59528 9.92369C8.13795 10.325 7.70862 10.549 7.35395 10.661L8.46462 8.05703C8.81928 7.22636 8.52062 6.93703 8.16595 6.93703C7.68995 6.93703 7.12995 7.35703 6.50462 8.42103L6.55128 8.44903Z"
            fill="#1A1D20"
        />
    </svg>
);

const TipsToImproveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.00065 0.833008H10.0007V2.16634H12.6673V6.49967H12.0007V2.83301H10.0007V4.16634H4.00065V2.83301H2.00065V14.1663H6.00065V14.833H1.33398V2.16634H4.00065V0.833008ZM4.66732 1.49967V3.49967H9.33398V1.49967H4.66732ZM12.334 7.3616L14.8054 9.83301L9.47206 15.1663H7.00065V12.6949L12.334 7.3616ZM7.66732 12.9711V14.4997H9.19591L13.8626 9.83301L12.334 8.30441L7.66732 12.9711Z"
            fill="#1A1D20"
        />
    </svg>
);

interface WelcomeButtonsProps {
    isDisplayedDesktop?: boolean;
    isDisplayedMobile?: boolean;
}

export const WelcomeButtons: React.FC<WelcomeButtonsProps> = ({ isDisplayedDesktop, isDisplayedMobile }) => {
    const dispatch = useAppDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const containerDisplay = `
    ${isDisplayedDesktop ? 'welcomeButtons_desktopVisible' : 'welcomeButtons_desktopHidden'} 
    ${isDisplayedMobile ? 'welcomeButtons_mobileVisible' : 'welcomeButtons_mobileHidden'}
`.trim();

    const addMessageToChat = async (messageType: MessageType): Promise<void> => {
        const chatInput: IChatMessage = {
            server: messageType,
            human: '',
            ai: '',
            date: '',
            evidence: [],
            model_name: '',
        };

        try {
            if (selectedId) {
                dispatch(addMessageToConversationFromUser({ message: chatInput, chatId: selectedId }));
            } else {
                const id = await chat.createChat();
                dispatch(addMessageToConversationFromUser({ message: chatInput, chatId: id ?? '' }));
            }
        } catch (err) {
            console.error(err);
        }
    };

    if (selectedId) {
        return null;
    }

    return (
        <div className={`${styles.container} ${containerDisplay}`}>
            <div className={mergeClasses(styles.button, "gtmPromptStudioAccess")} onClick={() => dispatch(setSelectedTab('promptLibrary'))}>
                <PromptLibraryIcon />
                <span className="gtmPromptStudioAccess">Prompt studio</span>
            </div>
            <div className={styles.button} onClick={() => void addMessageToChat(MessageTypes.HELP)}>
                <HelpBuildPromptIcon />
                <span>Help build prompt</span>
            </div>
            <div className={styles.button} onClick={() => void addMessageToChat(MessageTypes.TIPS)}>
                <TipsToImproveIcon />
                <span>Tips to improve</span>
            </div>
        </div>
    );
};
